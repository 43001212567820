import Thing from '@/model/Thing';
import WorstHeader from '../widgets/WorstHeader';
import { Alert } from '@mui/material';

import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';

function VotePage() {
    const { thing1, thing2 } = useParams<{ thing1: string, thing2: string }>();
    const navigate = useNavigate();
    const rankedItemsJSON = localStorage.getItem('RankedItems')
    const rankedItems = rankedItemsJSON ? JSON.parse(rankedItemsJSON) : [];
    const shouldShowAuth = true;
    
    const voteButtonClicked = (value1: string|undefined, value2: string|undefined) => {
        // TODO: check auth
        if (shouldShowAuth) {
            navigate(`/auth`) // TODO: possibly
            return;
        } else {
            // TODO: call vote api
            navigate(`/vote/${value1}/${value2}`);
        }
    };

    var randomItem: any = "DEFAULT";
    if (rankedItems != null) { // TODO: initiate a fetch of Get Items and store (if missing)
        do {
            randomItem = rankedItems[Math.floor(Math.random() * rankedItems.length)]
        } while (thing1 === randomItem.id || thing2 === randomItem.id)
    }

    return (
        <div>
            <WorstHeader></WorstHeader>
            <div className="VotePageBody">
                <h2>Which is worse?</h2>
                <Button className="VoteButton" onClick={() => voteButtonClicked(thing1, randomItem.id)}>{thing1}</Button>
                <p>or</p>
                <Button className="VoteButton" onClick={() => voteButtonClicked(thing2, randomItem.id)}>{thing2}</Button>
            </div>
        </div>
    )
}

export default VotePage;