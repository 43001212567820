import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { Amplify} from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { useNavigate, useParams } from 'react-router-dom';
import WorstHeader from '../widgets/WorstHeader';

const amplifyConfig = {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_xxxxxxxx',
      userPoolWebClientId: 'xxxxxxxxxxxxxxxxxxxxxxxxxx',
      authenticationFlowType: 'CUSTOM_AUTH',
    },
  };

  // TODO: Configure Amplify auth
// Amplify.configure({
//     Auth: amplifyConfig
// });

function AuthPage() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    function navigateBack() {
        navigate(-1);
    }

    const handleSendOtp = async () => {
        setError('');
        try {
          // Send OTP to phone number
          // TODO: Handle sign in/up
        //   await Auth.signIn(phoneNumber);
          setIsOtpSent(true);
          console.log('OTP sent');
        } catch (err) {
          setError('Error sending OTP');
          console.error(err);
        }
      };
    
      const handleVerifyOtp = async () => {
        setError('');
        try {
          // Verify the OTP
          // TODO: handle OTP stuff
        //   const user = await Auth.sendCustomChallengeAnswer(phoneNumber, otp);
        //   console.log('User signed in:', user);
          alert('Sign-in successful');
          navigateBack();
        } catch (err) {
          setError('Error verifying OTP');
          console.error(err);
        }
      };
    
      return (
        <div>
            <WorstHeader></WorstHeader>
            <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            p={3}
            >
            <Typography variant="h4" gutterBottom>
                Sign In / Sign Up
            </Typography>
        
            {!isOtpSent ? (
                <>
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="+1234567890"
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSendOtp}
                >
                    Send OTP
                </Button>
                </>
            ) : (
                <>
                <TextField
                    label="Enter OTP"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleVerifyOtp}
                >
                    Verify OTP
                </Button>
                </>
            )}
        
            {error && (
                <Typography color="error" variant="body2" mt={2}>
                {error}
                </Typography>
            )}
            </Box>
        </div>
      );
}

export default AuthPage;