import Thing from '@/model/Thing';

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

function RankedListItem(thing: Thing, onClickFunction: () => void) {
    return (
        <ListItemButton className="List-item" 
                key={thing.name}
                onClick={onClickFunction}>
            <ListItemIcon>{thing.rank}</ListItemIcon>
            <ListItemText primary={thing.name} secondary={thing.detail} />
        </ListItemButton>
    )
}

export default RankedListItem