import AddThingButton from './widgets/AddThingButton'
import RankedListItem from './widgets/RankedListItem'
import WorstHeader from './widgets/WorstHeader'
import Thing from './model/Thing'
import AuthPage from './pages/AuthPage'
import VotePage from './pages/VotePage'
import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import HelpPage from './pages/HelpPage'
import NewThingPage from './pages/NewThingPage'


const API_URL = 'https://piv6ju6e9l.execute-api.us-east-1.amazonaws.com/things'

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/vote/:thing1/:thing2" element={<VotePage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/addThing" element={<NewThingPage />} />
      </Routes>
    </Router>

    
  );
}

function HomePage() {
  return (
    <div className="App">
      <WorstHeader></WorstHeader>
      <h2>Democratizing trash talk</h2>
      <ListOfThings></ListOfThings>
      <AddThingButton></AddThingButton>
    </div>
  )
}

function ListOfThings() {
  const [items, setItems] = useState<Thing[]>([]);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    console.info('Init ListOfThings')
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.info('Response: ' + response)

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const retrievedItems = await response.json();
        // TODO: Wrap this in a class the save/get RankedItems
        // Store json
        localStorage.setItem('RankedItems', JSON.stringify(retrievedItems));
        
        // Convert to Thing
        const things = retrievedItems.map(
          (item: any) => new Thing(item.ranking, item.id, item.detail))
        // Sort by rank
        things.sort((a: Thing, b: Thing) => b.rank - a.rank);
        // Update state
        setItems(things);
      } catch (err) {
        setError('Failed to fetch data:' + err);
        console.error(err);
      }
    };
  
    fetchData();
  }, []);


  if (error) {
    return <p>{error}</p>;
  }

  const handleButtonClick = (value1: string, value2: string) => {
    navigate(`/vote/${value1}/${value2}`);
  };

  return (
    <div className="List-container">
      <List className="Ranked-list">
        {items.map(
          (item) => {
            var randomItem: Thing;
            do {
              randomItem = items[Math.floor(Math.random() * items.length)]
            } while (item.name === randomItem.name)
            
            return RankedListItem(item, () => handleButtonClick(item.name, randomItem.name))
          }
        )}
      </List>
    </div>
  );
}

export default App;
