

class Thing {
    rank: number;
    name: string;
    detail: string;

    constructor(rank: number, name: string, detail: string) {
        this.rank = rank;
        this.name = name;
        this.detail = detail;
    }
}

export default Thing