import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import '../css/WorstHeader.css'
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';

function WorstHeader() {
    const navigate = useNavigate();

    const headerLinkClicked = () => {
        navigate('/')
    }

    const helpIconClicked = () => {
        navigate('/help')
    }

    return (
        <header className="App-header">
            <Button onClick={headerLinkClicked}><h1>Worst.vote</h1></Button>
            <IconButton className="HelpIcon" onClick={helpIconClicked}>
                <HelpIcon/>
            </IconButton>
        </header>
    );
}

export default WorstHeader;