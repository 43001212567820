import { Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import '../css/NewThing.css'


function AddThingButton() {
    const navigate = useNavigate();
    
    const fabButtonClicked = () => {
        navigate('/addThing');
    };

    const cancelButtonClicked = () => {
        navigate('/');
    };

    return (
        <div>
            <Fab className="AddThingButton" onClick={fabButtonClicked}>
                <AddIcon />
            </Fab>
        </div>
    );
}

export default AddThingButton;