import { Button } from "@mui/material";
import WorstHeader from '../widgets/WorstHeader';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const API_URL = 'https://piv6ju6e9l.execute-api.us-east-1.amazonaws.com/things'

function NewThingPage() {
    const [inputText, setInputText] = useState<string>("");
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();

    const cancelButtonClicked = () => {
        navigate('/');
    };

    const submitButtonClicked = () => {
        const url = `${API_URL}?name=${encodeURI(inputText)}` 
            const submitData = async () => {
              try {
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
        
                console.info('Response: ' + response)
    
              } catch (err) {
                setError('Failed to submit data:' + err);
                console.error(err);
              }
            };
          
            submitData();
    };
    

    const textFieldChanged = (event: any) => {
        setInputText(event.target.value);
    };

    return (
        <div>
            <WorstHeader></WorstHeader>
            <h1>Add a new worst thing</h1>
            <TextField id="outlined-basic" label="TODO: label" variant="outlined"
            onChange={textFieldChanged}/>
            <Button onClick={submitButtonClicked}>Submit</Button>
            <Button onClick={cancelButtonClicked}>Cancel</Button>
        </div>
    );
}

export default NewThingPage;