import { Alert, IconButton, List } from '@mui/material';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/HelpPage.css'

function HelpPage() {
    const navigate = useNavigate();
    
    const exitButtonClicked = () => {
        navigate(-1);
    };

    return (
        <div className="AppContainer">
            <header className="App-header">
                <h1>Help</h1>
                <IconButton className="CloseButton" onClick={exitButtonClicked}>
                    <CloseIcon/>
                </IconButton>
            </header>
            <div className="FAQ">
                <List>
                    <h1>What is Worst.vote?</h1>
                    <p>Worst.vote is the source of truth on the world's worst things. People can vote on which things are worse, and submit their own awful things.</p>
                    <h1>How does it work?</h1>
                    <ol>
                        <li>The homepage shows the current list of worst things.</li>
                        <li>Click on any thing in the list to vote it up or down.</li>
                        <li>Click on the + button in the bottom right to add a new thing to the list.</li>
                        <li>Share links to anything that you think should top the list.</li>
                    </ol>
                    <h1>Why do you need my phone number?</h1>
                    <p>We try to keep users anonymous, so we use only a phone number to prevent bots from ruining the fun.</p>
                </List>
            </div>
        </div>
    )
}

export default HelpPage;